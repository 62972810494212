import Vue from 'vue';
import Engage from '@engage_so/js';
import cfg from '@/services/cfg';

if (cfg.engage?.publicKey) {
  Engage.init(cfg.engage.publicKey);

  Vue.mixin({
    beforeCreate() {
      this.$engage = Engage;
    }
  });
}

export default Engage;
