import BaseModel from './base.model';
import moment from '@/services/moment';
import i18n from '@/i18n';

export default class AuditLog extends BaseModel {
  getModelName() {
    return 'AuditLog';
  }

  get icon() {
    if (!this.item) {
      return '';
    }
    if (this.data && this.data.icon) {
      return this.data.icon;
    }
    switch (this.item.model) {
      case 'Contact':
        return 'people';
      case 'Project':
        return 'checklist';
      case 'Contract':
        return 'autorenew';
      case 'Asset':
        return this.data?.type?.icon ? this.data.type.icon : 'widgets';
      case 'AssetType':
        return 'widgets';
      case 'Invoice':
        return 'receipt';
      case 'Payment':
        return 'attach_money';
      case 'Offering':
        return 'local_offer';
      case 'Account':
        return 'settings';
      case 'Sale':
        return 'local_play';
      case 'Integration':
        return 'extension';
      case 'User':
        return 'touch_app';
      case 'CreditCard':
        return 'credit_card';
      default:
        return 'error_outline';
    }
  }

  get editRoute() {
    if (!this.item || !this.item.id) {
      return '';
    }
    const { id, model } = this.item;
    switch (model) {
      case 'Sale':
        return `/workspace/sales/view/${id}`;
      case 'Project':
        return `/projects/view/${id}`;
      case 'Contract':
        return `/workspace/services/view/${id}`;
      case 'Asset':
        return `/workspace/assets/view/${id}`;
      case 'Invoice':
        return `/billing/invoices/view/${id}`;
      case 'Payment':
        return `/billing/payments/view/${id}`;
      case 'Contact':
        return `/directory/clients/view/${id}`;
      case 'User':
        return `/directory/users/edit/${id}`;
      case 'Account':
        return `/account`;
      case 'Offering':
        return `/workspace/offerings/view/${id}`;
      case 'AssetType':
        return `/workspace/assets/types/${id}`;
      case 'Integration':
        return `/integrations`;
      case 'CreditCard':
        return `/billing/credit-cards/edit/${id}`;
      default:
        return '';
    }
  }

  get oneLiner() {
    const { user, date, type } = this;
    const today = moment();
    const relativeDate = moment(date).from(today);
    if (this.item) {
      const item = this.item.model.toLowerCase();
      return `${user ? user.name : 'Controlla' } ${type}d ${item} ${relativeDate}`;
    }
    return `${user.name} ${type}d ${relativeDate}`;
  }

  /**
   * One liner show on item history (on an individual item page)
   */
  oneLinerItemHistory(tz) {
    const oneLinerItemHistoryIcon = updateType => {
      switch (updateType) {
        case 'create': return 'flare';
        case 'update': return 'edit';
        case 'email': return 'email';
        default: return 'edit';
      }
    };
    const { user, date, action, type } = this;
    const today = moment();
    const relativeDate = moment(date).from(today);
    const icon = oneLinerItemHistoryIcon(type);

    if (this.item) {
      return `
        <div class="HistoryItemTitle">
          <div class="IconHistoryWrap" >
            <i class="Icon-HistoryItem">${icon}</i>
          </div>
          <div>
            <span class="Who">${user ? user.name : 'Controlla'}</span>
            <span class="What">${i18n.t(`audit_log.${action}`)}</span>
            <div class="When TooltipUnder--modalHistory">
              ${relativeDate}
              <div class="Tooltip-text">
                ${moment(date).tz(tz).format('DD MMM YYYY - h:mma')} <br />
                <div class="Tooltip-context">${tz}</div>
              </div>
            </div>
          </div>
        <div>
        `;
    }
    return '';
  }

  /**
   * One liner for Lists - e.g. Recent Updates (on Dashboard)
   */
  oneLinerRecentUpdates(tz) {
    const { user, date, item, action } = this;
    const today = moment();
    const relativeDate = moment(date).from(today);
    if (item) {
      return `
        <span class="When-wrap">
          <span class="When TooltipUnder--update">
            ${relativeDate}
            <span class="Tooltip-text">
              ${moment(date).tz(tz).format('DD MMM YYYY - h:mma')} <br />
              <div class="Tooltip-context">${tz}</div>
            </span>
          </span>
          <span> - ${user ? user.name : 'Controlla'} ${i18n.t(`audit_log.${action}`)}</span>
        </span>
      `;
    }
  }

  get displayModelClass() {
    if (!this.item || !this.item.model) {
      return '';
    }
    switch (this.item.model) {
      case 'Account':
        return 'update-topic-4';
      case 'Asset':
        return 'update-topic-1';
      case 'AssetType':
        return 'update-topic-4';
      case 'Contact':
        return 'update-topic-3';
      case 'Contract':
        return 'update-topic-2';
      case 'CreditCard':
        return 'update-topic-8';
      case 'Integration':
        return 'update-topic-4';
      case 'Invoice':
        return 'update-topic-5';
      case 'Offering':
        return 'update-topic-7';
      case 'Payment':
        return 'update-topic-6';
      case 'Project':
        return 'update-topic-3';
      case 'Sale':
        return 'update-topic-1';
      case 'User':
        return 'update-topic-4';
      default:
        return 'update-topic-1';
    }
  }

  get displayModel() {
    if (!this.item || !this.item.model) {
      return '';
    }
    switch (this.item.model) {
      case 'Account':
        return 'recent_update.account';
      case 'Asset':
        return this.data?.type?.name ? this.data.type.name : 'recent_update.asset';
      case 'AssetType':
        return 'recent_update.asset_type';
      case 'Contact':
        return 'recent_update.client';
      case 'Contract':
        return 'recent_update.contract';
      case 'CreditCard':
        return 'recent_update.credit_card';
      case 'Integration':
        return 'recent_update.integration';
      case 'Invoice':
        return 'recent_update.invoice';
      case 'Offering':
        return 'recent_update.offering';
      case 'Payment':
        return 'recent_update.payment';
      case 'Project':
        return 'recent_update.project';
      case 'Sale':
        return 'recent_update.sale';
      case 'User':
        return 'recent_update.user';
      default:
        return '';
    }
  }
}
