<script>
import { mapState } from 'vuex';
import Asset from '@/models/asset.model';
import AssetApi from '@/api/asset.api';
import AssetTypeApi from '@/api/asset-type.api';
import OverviewOnboarding from '@/components/shared/overview-onboarding';
import ItemView from './modals/view';
import AssetEdit from './modals/edit';
import AssetArchive from './modals/archive';
import TypesView from './modals/asset-types/types-view';
import FilterButtons from './filter-buttons.vue';

export default {
  components: { FilterButtons, OverviewOnboarding },

  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      assets: [],
      assetTypes: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),

    hasItems() {
      return this.assets.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    manualAssetTypes() {
      return this.assetTypes.filter(({integration}) => !integration);
    },
  },

  created() {
    this.setupFilter();
    this.setupPage();
    this.loadAssetTypes();
    this.loadAssets();
    this.processAction();
  },

  // Clear filter on browsing away from page
  beforeRouteLeave(to, from, next) {
    this.filter.onChange(() => { });
    this.filter.resetToDefaults();
    next();
  },

  methods: {
    async setupFilter() {
      this.filter = this.$filter.get('assets');

      this.filter.setDefaults({
        search: '',
        sort: 'name',
        from: null,
        to: null,
        by: '',
        hasContract: null,
        hasContact: null,
        isArchived: false,
        type: null,
      });

      this.filter.loadValues(this.$route.query);
      this.filter.onChange(() => {
        this.loadPage(1)
      });
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    async loadAssets() {
      this.isLoading = true;
      const filter = this.makeFilter();
      await AssetApi
        .query(filter)
        .then(data => {
          this.assets = data.assets;
          this.totalItems = data.meta.total;
          this.isLoading = false;
        }) 
    },

    async loadAssetTypes() {
      this.assetTypes = await AssetTypeApi.query().then(({ items }) => items);
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'add') {
        return this.add();
      }

      if (action === 'edit' && id) {
        const asset = await AssetApi.findById(id);
        if (asset) {
          this.edit(asset);
        }
      }

      if (action === 'view' && id) {
        const asset = await AssetApi.findById(id);
        if (asset) {
          this.view(asset);
        }
      }

      if (action === 'types' && id) {
        this.showTypes(id);
      }

      if (action === 'assetTypes') {
        this.showTypes();
      }
    },

    add() {
      if (this.manualAssetTypes.length === 0) {
        this.$modal.show(
          TypesView,
          { addNewNow: true },
          { maxWidth: 1000 },
          { closed: () => this.loadAssetTypes() },
        );
        return;
      }

      const { clientId, clientName } = this.$route.query;
      const item = new Asset();
      if (clientId && clientName) {
        item.contact = { id: clientId, name: clientName };
      }
      this.edit(item, false);
    },

    view(item) {
      this.$store.dispatch('session/loadAccount');

      const onEdit = async (editOwner = false) => this.edit(item, true, editOwner);

      this.$modal.show(
        ItemView,
        { item, onEdit, onArchive: this.archive },
        { maxWidth: 1000 },
        {
          opened: () => { this.$router.push({ name: 'assets', params: { action: 'view', id: item.id } }); },
          closed: () => { this.$router.replace({ name: 'assets' }); },
        }
      );
    },

    showTypes(id) {
      this.$modal.hideAll();
      this.$modal.show(
        TypesView,
        { typeId: id, assetTypes: this.assetTypes },
        { maxWidth: 1000 },
        { closed: () => this.loadAssetTypes() },
      );
    },

    async edit(item, isEdit = true, editOwner = false) {
      const onSave = async model => {
        if (model.contact && !model.contact.id) {
          model.contact = await model.contact.save();
        }
        return item.save(model)
          .then(() => this.onSaved(item, isEdit));
      };

      const onArchive = () => item.archive().then(this.onArchived);

      this.$modal.show(
        AssetEdit,
        { item, isEdit, onSave, onArchive, showTypes: this.showTypes, types: this.assetTypes, editOwner },
        {},
        {
          opened: () => { this.$router.push({ name: 'assets', params: { action: 'edit', id: item.id } }); },
          closed: () => { this.$router.replace({ name: 'assets' }); },
        }
      );
    },

    async onSaved(asset, isEdit) {
      this.$notice.show('notices.asset_saved');

      if (!this.user.experiences.asset_created) {
        this.user.experiences.asset_created = true;
        this.user.updateOwn(this.user);
      }

      const updatedAsset = await AssetApi.findById(asset.id);
      this.view(updatedAsset);

      //Reload assets if we added a new one
      if (!isEdit) {
        this.loadAssets(true);
      }
    },

    archive(item) {
      const onArchive = () => item.archive().then(this.onArchived);

      this.$modal.show(AssetArchive, { item, onArchive });
    },

    unArchive(item) {
      return item.unArchive().then(this.onUnArchived);
    },

    onArchived() {
      this.$notice.show('notices.item_archived');
      this.loadAssets(true);
    },

    onUnArchived() {
      this.$notice.show('notices.item_restored');
      this.loadAssets(true);
    },

    loadPage(page) {
      this.page = page;
      this.loadAssets(true);
    },

    makeFilter(extra) {
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="assets_overview.title"
      
      :filter="filter"
      :total-items="totalItems"
      @add="add"
      :page="page"
      :num-pages="numPages"
      :loadPage="loadPage"
    >
      <template slot="settings">
        <setting-options>

          <li>
            <a @click="showTypes()"><i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}</a>
          </li>
          <li>
            <a @click="$router.push({ name: 'integrations' })">
              <i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}
            </a>
          </li>
          <hr />
          <li>
            <a href="/workspace/assets/?hasContract=false">
              <i class="Icon-Base--warning">info</i>{{$t('shared_settings-options.unserviced')}}
            </a>
          </li>
          <li>
            <a href="/workspace/assets/?hasContact=false">
              <i class="Icon-Base--danger">error_outline</i>{{$t('shared_settings-options.no_owner')}}
            </a>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_assets_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_assets')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>

      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': filter.isArchived === false }">
          <span @click="filter.resetToDefaults()">{{ $t('assets_overview.filter_button_all') }}</span>
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': filter.isArchived === true }">
          <span @click="filter.update('isArchived', true);">{{ $t('assets_overview.filter_button_archived') }}</span>
        </div>
      </template>
    </page-meta>

    <div class="PageContents">
      <div class="Container">
        <filter-buttons :filter="filter" />

        <div class="Table">
          <overview-onboarding experience="assets_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <img src="@/assets/images/onboard/logistics.svg" alt="Assets Onboarding">
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{ $t('onboarding_assets.assets_title') }}</h2>
              <div class="OnboardingIntro">
                {{ $t('onboarding_assets.assets_intro') }}
              </div>

              <p>
                <router-link to="/integrations">{{ $t('onboarding_assets.assets_integration_link') }}</router-link>
                {{ $t('onboarding_assets.assets_regular_text') }}
                <a @click="showTypes()">{{ $t('onboarding_assets.assets_alternative_link') }}</a>.
              </p>
              <i18n path="onboarding_assets.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_assets.read_more.link')" target="_blank"
                    :title="$t('onboarding_assets.read_more.title')">
                    {{ $t('onboarding_assets.read_more.urltext') }}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>
          <div class="Table-header">
            <div class="Table-col--S">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('global.item') }}
              </sort>
            </div>
            <div class="Table-col--XS Hide-BreakPointMedium">
            </div>
            <div class="Table-col--M Hide-BreakPointSmall">
              <sort :sort="filter.sort" field="contact.name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('assets_overview.owner') }}
              </sort>
            </div>
            <div class="Table-col--options" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="asset in assets" :key="asset.id" class="Table-row">
              <div class="Table-col--S Table-col--primary" @click="view(asset)">
                <div class="ItemM">
                  <icon class="Item-Icon" :name="asset.assetType?.icon" />
                  <!-- <i class="Icon-Connection">circle</i> /* TEMPORARILY HIDDEN UNTIL MHJB ADDS CONNETION INDICATOR FUNCTION */ -->
                  <div class="Item-TextWrap">
                    <div class="Item-Headline">{{ asset.name }}</div>
                    <div class="Description">{{ asset.assetType.name }}</div>
                  </div>
                </div>
              </div>

              <div class="Table-col--XS Hide-BreakPointMedium">
                <div class="Table-Tally">
                  <span class="Table-Tally--services" v-if="asset.contractIds.length === 1">1
                    {{$tc('global.service_plural', asset.contractIds.length)}}</span>                </div>
              </div>

              <div class="Table-col--M Hide-BreakPointSmall">
                <span v-if="asset.contact">{{ asset.contact.name }}</span>
                <span v-else-if="!asset.archived" class="Table-Value--exception">
                  <i class="Icon-Base--warning">error_outline</i>
                  <a @click="edit(asset, true, true)">{{ $t('assets_overview.add_owner') }}</a>
                </span>
              </div>
              <div class="Table-col--options">
                <setting-options
                  v-if="asset.archived"
                  :options="['view', 'unArchive']"
                  @view="view(asset)"
                  @un-archive="unArchive(asset)"
                />
                <setting-options
                  v-else-if="asset.contractIds.length > 0"
                  :options="['view', 'edit', 'archive-disabled']"
                  @view="view(asset)"
                  @edit="edit(asset)"
                />
                <setting-options
                  v-else
                  :options="['view', 'edit', 'archive']"
                  @view="view(asset)"
                  @edit="edit(asset)"
                  @archive="archive(asset)"
                />
              </div>
            </div>
          </div>
        </div>

        <pagination
          :show-all="totalItems < 500"
          :page="page"
          class="Pagination--lower"
          :num-pages="numPages"
          @change="loadPage($event.page)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>