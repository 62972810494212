export default {
  app: {
    baseUrl: 'http://localhost:8080'
  },
  api: {
    baseUrl: 'http://localhost:8081/api'
  },
  storage: {
    prefix: 'controlla_test'
  },
  auth: {
    clientId: 'test-client',
    homeRoute: 'dashboard',
    loginRoute: 'login'
  },
  errors: {
    log: true
  },
  sentry: {
    dsn: '',
    tags: {}
  },
  stripe: {
    publishableKey: '',
    clientId: ''
  },
  engage: {
    apiKey: '',
    userId: ''
  },
  intercom: {
    appId: ''
  },
  mixpanel: {
    token: ''
  },
  env: 'test'
}; 