import BaseModel from './base.model';
import UserApi from '@/api/user.api';

export default class User extends BaseModel {
  getModelName() {
    return 'User';
  }

  get initials() {
    //Get name parts
    const parts = this.name.split(' ');

    //Only one part?
    if (parts.length === 1) {
      return parts[0][0].toUpperCase();
    }

    //Get first name
    const firstName = parts.shift();
    const initials = [firstName[0]];

    //Make sure we get the last name correctly, and avoid grabbing prefixes etc.
    const part = parts.find(part => part[0] === part[0].toUpperCase());
    if (part) {
      initials.push(part[0]);
    }
    else if (parts[0]) {
      initials.push(parts[0][0]);
    }

    //Return initials
    return initials
      .join('')
      .toUpperCase();
  }

  // Only used for the dashboard onboarding panels at this stage, not overview onboarding panels
  get onboardingPanels() {
    return {
      main: this.experiences?.onboarding_panel,
      stripe: this.experiences?.onboarding_panel_stripe,
    }
  }

  get stepsDone() {
    const { experiences } = this;
    return {
      clients: experiences?.client_added,
      assets: experiences?.asset_created,
      offerings: experiences?.offering_created,
      contracts: experiences?.contract_created,
    }
  }

  get hasGoogleProfile() {
    const { profiles } = this;
    return profiles?.some(({ provider }) => provider === 'google');
  }

  invite(data) {
    return UserApi
      .invite(data);
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return UserApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {
    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return UserApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  /**
   * Update own user
   */
  updateOwn(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return UserApi
      .updateOwn(json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return UserApi
      .remove(id);
  }

  archive() {
    return this.update({ archived: true });
  }

  unArchive() {
    return this.update({ archived: false });
  }
}
