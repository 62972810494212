<script>
import { mapState } from 'vuex';
import dropMenu from '@/components/shared/drop-menu';
import NotificationApi from '@/api/notification.api';

export default {
  components: {
    dropMenu,
  },

  data() {
    return {
      notifications: [],
      currentOn: '',
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
      frillWidgetUnreadCount: state => state.session.frillWidgetUnreadCount,
      dropMenuOpen: state => state.session.dropMenuOpen,
    }),

    numUnreadNotifications() {
      return this.unreadNotifications.length;
    },

    unreadNotifications() {
      return !this.notifications ?
        [] : this.notifications.filter(notification => !notification.isRead);
    },

    readNotifications() {
      return !this.notifications ?
        [] : this.notifications.filter(notification => notification.isRead);
    },

  },

  mounted() {
    this.startCheckingForNotifications();
  },

  destroyed() {
    this.stopCheckingForNotifications();
  },

  methods: {
    logout() {
      this.$auth.logout();
    },

    startCheckingForNotifications() {
      this.checkForNotifications();
      this.notificationCheckInterval = setInterval(() => {
        this.checkForNotifications();
      }, 30 * 1000);
    },

    stopCheckingForNotifications() {
      clearInterval(this.notificationCheckInterval);
    },

    async checkForNotifications() {
      //No user
      if (!this.user) {
        this.notifications = [];
        return;
      }
      await NotificationApi
        .query({
          limit: 10,
          sort: '-id',
        })
        .then(data => this.notifications = data.notifications)
        .catch(() => {
          // prevent endless auth errors
          this.stopCheckingForNotifications();
        });
    },

    /**
     * Mark currentOn tracking which menu is open
     */
    updateCurrentOn($event) {
      this.currentOn = $event;
      if (!this.currentOn) {
        this.$refs.notifications.reset();
      }
    },

  },
};
</script>

<template>
  <div class="UserLinks">
    <div class="Menu-item  Menu-item--notification">
      <drop-menu :on-click="updateCurrentOn" :currentOn="currentOn">
        <template slot="trigger">
          <a class="MainNav-link MainNav-link--notifications MainNav-link--nowash Tooltip--right">
            <i :class="'Icon-MainNav Icon--notifierNotifications' + (numUnreadNotifications == 0 ? ' Icon--no-unread' : '')"
              :data-num-unread="numUnreadNotifications">notifications</i>
            <span class="MenuTitle-full">{{ $t('app_header_userlinks.notifications.menu_title') }}</span>
            <div v-if="!dropMenuOpen" class="Tooltip-text">{{ $t('app_header_userlinks.notifications.menu_title') }}
            </div>
          </a>
        </template>
        <template slot="Content--mediumWidth">
          <div class="UserNotifications-content">
            <tabs ref="notifications" :options="{ useUrlFragment: false }">
              <tab :name="$t('app_header_userlinks.notifications.tab_unread')">
                <transition-group
                  name="Notification"
                  tag="div"
                >
                  <div
                    v-for="notification in unreadNotifications"
                    :key="notification.id"
                    class="Notification"
                  >
                    <div class="Notification-Title">
                      {{ notification.title }}
                    </div>
                    <div class="Notification-Content">
                      {{ notification.message }}
                    </div>
                    <div class="Notification-links">
                      <span class="Notification-Link">
                        <router-link :to="notification.actionRoute">{{ notification.actionTitle }}</router-link>
                      </span>
                      |
                      <span class="Notification-MarkAsRead"><a @click="notification.markRead()">{{
                          $t('app_header_userlinks.notifications.mark_as_read')
                      }}</a></span>
                    </div>
                  </div>
                </transition-group>
                <div v-if="unreadNotifications.length == 0" class="Notification"  :class="{ 'Notification-Blank': unreadNotifications.length == 0 }">
                  <div class="no-unread">
                    {{ $t('app_header_userlinks.notifications.content_no_unread') }} <i
                      class="Icon-Base">sentiment_very_satisfied</i>
                  </div>
                </div>
              </tab>
              <tab :name="$t('app_header_userlinks.notifications.tab_read')">
                <div class="Notification" v-for="notification in readNotifications" :key="notification.id">
                  <div class="Notification-Title">{{ notification.title }}</div>
                  <div class="Notification-Content">
                    {{ notification.message }}
                  </div>
                  <div class="Notification-links">
                    <span class="Notification-Link">
                      <router-link :to="notification.actionRoute">{{ notification.actionTitle }}</router-link>
                    </span>
                  </div>
                </div>
                <div v-if="readNotifications.length == 0" class="Notification Notification-Blank">
                  <div class="no-unread">
                    {{ $t('app_header_userlinks.notifications.content_no_unread') }} <i
                      class="Icon-Base">sentiment_very_satisfied</i>
                  </div>
                </div>
              </tab>
            </tabs>
          </div>
        </template>
      </drop-menu>
    </div>

    <div class="Menu-item Menu-item--adminMenu">
      <drop-menu :on-click="updateCurrentOn" :currentOn="currentOn">
        <template slot="trigger">
          <div :class="{
              'MainNav-link': true,
              'MainNav-link--nowash': true,
              'MainNav-link--avatar': true,
              'Tooltip--right': true,
              'router-link-exact-active':
                $route.path.startsWith('/account') || $route.path.startsWith('/settings') || $route.path.startsWith('/profile')
            }">
            <span
              :class="'UserAvatar ' + (frillWidgetUnreadCount && frillWidgetUnreadCount > 0 ? 'Icon--notifierUpdates' : null)">{{
                  user
                    ? user.initials : ''
              }}</span>
            <span class="MenuTitle-full">{{ $t('app_header_userlinks.menu_title') }}</span>
            <div v-if="!dropMenuOpen" class="Tooltip-text">{{ $t('app_header_userlinks.menu_title') }}</div>
          </div>
        </template>
        <template slot="Content--mediumWidth">
          <div class="UserProfile-content">
            <div class="DropMenu-title">
              <div class="UserAvatar-menuWrap">
                <span class="UserAvatar-menu">{{ user ? user.initials : '' }}</span>
                <div class="UserAvatar-titleWrap">
                  <div class="UserName">{{ user ? user.name : '' }}</div>
                  <div class="UserRole">{{ user ? user.role : '' }}</div>
                </div>
              </div>
            </div>
            <hr />
            <router-link class="DropMenu-item" to="/settings/overview"><i class="Icon-Base">settings</i>{{
                $t('global.settings')
            }}</router-link>
            <router-link class="DropMenu-item" to="/account"><i class="Icon-Base">account_balance_wallet</i>{{ 
              $t('app_header_userlinks.account_plan') 
            }}
            </router-link>
            <router-link class="DropMenu-item" to="/profile"><i class="Icon-Base">face</i>{{
                $t('global.my_profile')
            }}</router-link>
 
            <hr />
            <a class="DropMenu-item" href="https://guide.controlla.io/" target="_blank" title="Controlla Help Center"><i
                class="Icon-Base">help</i>{{ $t('app_header_userlinks.help_centre') }} <i
                class="Icon-External">launch</i>
            </a>
            <frill-widget frill_key="199aadab-389f-4d2b-8837-d7a38b8f54ce"
              selector="frill-widget-whats-new DropMenu-item">
              <i class="Icon-Base">tips_and_updates</i>{{ $t('app_header_userlinks.whats_new') }}
            </frill-widget>
            <frill-widget frill_key="afa7a60e-f933-4fc4-92af-98675eadc853"
              selector="frill-widget-feature-suggestions DropMenu-item">
              <i class="Icon-Base">reviews</i>{{ $t('app_header_userlinks.suggest_features') }}
            </frill-widget>
            <a class="DropMenu-item" onclick="Beacon('open')">
              <i class="Icon-Base">headset_mic</i>{{ $t('app_header_userlinks.contact_us') }}
            </a>
            <!-- <a class="DropMenu-item">
              <i class="Icon-Base">bug_report</i>{{$t('app_header_userlinks.report_a_bug')}}
            </a> -->
            <hr />
            <a class="DropMenu-item" @click="logout"><i class="Icon-Base">logout</i> Logout</a>
          </div>
        </template>
      </drop-menu>
    </div>
  </div>
</template>

<style lang="scss">
.Menu-Open .User-Links {
  display: block;
}

.Menu-Separator {
  margin:$spacingS;
  border: solid 0.5px $colorWhiteFaded3;
}

.Menu-Closed {
  .Menu-Extra {
    display: flex;
    justify-content: center;
  }

  .Icon-Close {
    display: none;
  }

  .Icon-Open {
    display: block;
  }
}

.Menu-Open {
  .Menu-Extra {
    margin-left: 140px;
  }

  .Icon-Open {
    display: none;
  }
}

.UserLinks {

  &.Menu-item {
    margin: 178px 0;
  }

  .MenuTitle-full {
    margin-left: $spacingXL;
  }

  .MainNav-link--notifications {
    .MenuTitle-full {
      margin-left: 10px;
    }
  }

  .MainNav-link--avatar .MenuTitle-full {
    margin-left: 10px;
  }

  &.MainNav-link i {
    margin-left: 10px;
  }
}

.UserLinks .Menu-item {
  margin: $spacingXL 0;
  font-size: $fontSizeL;
}

.UserNotifications-content {
  padding:  $spacingL;
  height: 34rem;
  width: 24rem;
  border-radius: $borderRadiusM;
  overflow: hidden;
  line-height: $lineHeightM;

  .tabs-component {
    margin: - $spacingL;
    overflow: hidden;
    padding-top:  $spacingL;
    background: $colorBgNotifications;
  }

  :after {
    border: 10px solid;
    border-color: transparent $colorBgNotifications transparent transparent;
    content: "";
    left: -10px;
    margin-left: -10px;
    position: absolute;
    bottom: 103px;
  }

  .Notification {
    margin: $spacingM;
    font-size: $fontSizeS;
    border-radius: $borderRadiusM;
    padding: $spacingL $spacingL $spacingM;
    background: $colorWhite;
    @include shadows-subtle;
    border-top: 1px solid $colorBordersLight;
    border-right: 1px solid $colorBordersLight;
    border-left: 1px solid $colorBordersLight;
    border-bottom: 1px solid $colorBordersNormal;
    /* Following sets conditions for the notification to be read */
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s ease, transform 0.5s ease;
    &.Notification-Blank {
      background: none;
      border: none;
      box-shadow: none;
    }

    .Notification-Title {
      font-size: $fontSizeS;
      font-weight: $fontWeightBold;
      margin-bottom: $gapM;
    }

    .Notification-Content {
      color: $colorTextFaded1;
      font-size: $fontSizeXS;
      font-weight: $fontWeightNormal;
    }

    .no-unread {
      font-weight: $fontWeightMedium;
      margin: 35% 0$spacingS;
      text-align: center;
      font-size: $fontSizeL;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $gapS;
      .Icon-Base {
        font-size: 35px;
      }
    }

    .no-unreadOption {
      font-weight: normal;
      font-size: $fontSizeM;
      text-align: center;
    }

    .Notification-links {
      display: flex;
      justify-content: flex-start;
      gap: $gapL;
      margin: $spacingM 0 $spacingXS;
      font-size: $fontSizeXS;
      color: $colorTextFaded2;
    }
  }

  .Notification-leave-active {
    opacity: 0;
    transform: scale(0.85);
  }

  .tabs-component-panels {
    max-height: 30rem;
    overflow-x: scroll;
    background: $colorBgNotifications;
    padding: 0px;
    min-height: 480px;
    margin: $spacingM 0;
  }
}

.UserProfile-content {
  margin-top: $spacingS;
  padding: $spacingL;
  padding-bottom:  $spacingL;
  z-index: 1;

  &:after {
    border: 10px solid;
    border-color: transparent white transparent transparent;
    content: "";
    left: -10px;
    margin-left: -10px;
    position: absolute;
    bottom: 62px;
  }
}
</style>
