// MHJB: This is non-standard cf with other constants but may be more useful

export default {
  webflow: 'Webflow',
  dnsimple: 'DNSimple',
  stripe: 'Stripe',
  xero: 'Xero',
  digitalocean: 'DigitalOcean',
  quickbooks: 'QuickBooks',
};
