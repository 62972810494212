<script>
import { mapState } from 'vuex';
import AuditLogApi from '@/api/audit-log.api';
import IntegrationTypes from '@/constants/integration-types';

export default {
  data() {
    return {
      logs: [],
      initialLoading: true,
      loadingMore: false,
      offset: 0,
      totalLogs: 0,
    };
  },

  created() {
    this.loadLogs();
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    timezone() {
      return this.account?.timezone;
    },

    hasMore() {
      return this.logs.length < this.totalLogs;
    },
  },

  methods: {
    async loadLogs(append = false) {
      if (append) {
        this.loadingMore = true;
      } else {
        this.initialLoading = true;
      }

      await AuditLogApi
        .query({
          limit: 10,
          offset: this.offset,
          excludeVerbose: true,
        })
        .then(data => {
          const newLogs = data.logs;
          this.logs = append ? [...this.logs, ...newLogs] : newLogs;
          this.totalLogs = data.meta.total;
        })
        .finally(() => {
          this.initialLoading = false;
          this.loadingMore = false;
        });
    },

    async loadMore() {
      this.offset += 10;
      await this.loadLogs(true);
    },

    update_title(logItem) {
      if (!logItem) {
        return 'n/a';
      }
      if (logItem.model === 'Integration') {
        return IntegrationTypes[logItem.type];
      }
      if (logItem.model === 'Asset') {
        return logItem.name;
      }
      return logItem.name || (logItem.model + (logItem.type ? ' ' + logItem.type : '')) || 'n/a';
    },
  },
};
</script>

<template>
  <div class="PanelSection">
    <div class="Panel-Updates">
      <div class="PanelHeader">
        <div class="PanelHeader-title">
          {{ $t('audit_log.title') }}
        </div>
      </div>
      <div class="Updates">
        <div v-if="initialLoading">
          <div class="Recently-Updated--spinner">
            <spinner class="Spinner--general" />
          </div>
        </div>
      <div v-for="log in logs" :key="log.id">
        <router-link :to="log.editRoute" :class="(log.editRoute.length === 0 ? 'disabled' : '') + ' Update--link'">
          <div class="Update">
            <div class="Update--content">
              <div class="Update--title">
                <icon class="Icon-Update" :name="log.icon" />
                <div class="Update--titleText">{{ update_title(log.item) }}
                <span :class="(log.displayModelClass)"><span class="Update-Pipe">|</span>{{ $t(log.displayModel) }}</span></div>
              </div>
              <div class="Update--Meta" v-html="log.oneLinerRecentUpdates(timezone)" />
            </div>
          </div>
        </router-link>
      </div>
      <div v-if="hasMore && !initialLoading" class="LoadMore">
        <button class="Button--secondary" @click="loadMore" :disabled="loadingMore">
          <spinner v-if="loadingMore" class="Spinner--button" />
          <span v-else>{{ $t('common.load_more') }}</span>
        </button>
      </div>
    </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.LoadMore {
  display: flex;
  justify-content: center;
  padding: $spacingL 0;

  .Spinner--button {
    width: 20px;
    height: 20px;
  }
}
</style>
