<script>
import { mapState } from 'vuex';
import ContactApi from '@/api/contact.api';
import ContractApi from '@/api/contract.api';
import Contract from '@/models/contract.model';
import ModalArchive from '@/components/shared/modal-archive';
import OverviewOnboarding from '@/components/shared/overview-onboarding';
import Contact from '@/models/contact.model';
import ContractView from './modals/view';
import ContractEdit from './modals/edit';
import FilterButtons from '../assets/filter-buttons.vue';

export default {
  components: { OverviewOnboarding, FilterButtons },

  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      numContacts: 0,
      contracts: [],
      isLoading: false,
      meta: {
        active: 0,
        finished: 0,
        scheduled: 0,
      },
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
      account: state => state.session.account,
    }),

    hasItems() {
      return this.contracts.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    clientsExist() {
      // return this.account.contactsCount > 0;
      return true;
    },

    offeringsExist() {
      return this.account.offeringsCount > 0;
    },

  },

  async created() {
    this.$store.dispatch('session/loadAccount');
    this.setupFilter();
    this.setupPage();
    await this.loadContracts();
    await this.loadNumContacts();
    this.processAction();
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('contracts');

      this.filter.setDefaults({
        search: '',
        sort: 'name',
        from: null,
        to: null,
        by: '',
        status: 'active',
        offering: null,
      });

      this.filter.loadValues(this.$route.query);

      this.filter.onChange(() => {
        this.loadPage(1);
      });
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'add') {
        return this.add();
      }

      if (action === 'edit' && id) {
        const contract = await ContractApi.findById(id);
        if (contract) {
          this.edit(contract);
        }
      }

      if (action === 'view' && id) {
        const contract = await ContractApi.findById(id);
        if (contract) {
          this.view(contract);
        }
      }
    },

    add() {
      const { assetId, clientId, clientName, offeringId } = this.$route.query;
      const contract = new Contract();

      if (clientId && clientName && clientId !== 'undefined' && clientName !== 'undefined') {
        contract.contact = {
          id: clientId,
          name: clientName,
        };
      }

      const item = {};

      if (assetId && assetId !== 'undefined') {
        item.asset = assetId;
      }

      if (offeringId && offeringId !== 'undefined') {
        item.offering = offeringId;
      }

      contract.items = [item];

      this.edit(contract, false);
    },

    view(contract) {
      const { account } = this;

      const onEdit = async () => this.edit(contract);

      this.$modal.show(
        ContractView, 
        { account, contract, onEdit, onArchive: this.archive },
        { maxWidth: 1000 },
        {
          opened: () => this.$router.push({ name: 'services', params: { action: 'view', id: contract.id } }),
          closed: () => this.$router.replace({ name: 'services' }),
        },
      );
    },

    edit(contract, isEdit = true) {
      const onSave = async model => {
        // Create new contact if it's a new one
        if (typeof model.contact === 'string') {
          const newContact = new Contact();
          newContact.name = model.contact;
          await newContact.save();
          model.contact = newContact;
        }
        
        // Contract model has multiple items, but current implementation of add/edit form has just one
        model.items = [{
          offeringId: model.offering.id,
          price: model.price,
          description: model.description,
          assetId: model.asset,
        }];

        if (!model.name) {
          model.name = model.assetName ? `${model.assetName} – ${model.offering.name}` : model.offering.name;
        }

        return contract.save(model)
          .then(() => this.onSaved(contract));
      };

      this.$modal.show(
        ContractEdit,
        { contract, isEdit, onSave },
        { maxWidth: 500 },
        { 
          opened: () => this.$router.push({ name: 'services', params: { action: isEdit ? 'edit' : 'add', id: contract.id } }),
          closed: () => this.$router.replace({ name: 'services' }),
        },
      );
    },

    archive(contract) {
      const onArchive = () => contract.archive().then(this.onArchived);

      this.$modal.show(ModalArchive, {
        title: this.$t('contracts_overview.stop_archive_service'),
        onArchive,
        text: `<p>${this.$t('contracts_overview.confirm_stop_archive')} <strong>${contract.name}</strong></p>`,
      });
    },

    stop_archive(contract) {
      const onArchive = () => contract.archive().then(this.onArchived);

      this.$modal.show(ModalArchive, {
        title: this.$t('contracts_overview.stop_archive_service'),
        onArchive,
        text: `<p>${this.$t('contracts_overview.confirm_stop_archive')} <strong>${contract.name}</strong> – ${this.$t('contracts_overview.no_further_invoices')}</p>`,
      });
    },


    async onSaved(contract) {
      this.$notice.show('notices.service_saved');
      if (!this.user.experiences.contract_created) {
        this.user.experiences.contract_created = true;
        this.user.updateOwn(this.user);
      }
      this.loadContracts(true);
      const updatedContract = await ContractApi.findById(contract.id);
      this.view(updatedContract);
    },

    onArchived() {
      this.$notice.show('notices.service_archived');
      this.loadContracts(true);
    },

    async loadPage(page) {
      this.page = page;
      await this.loadContracts(true);
    },

    async loadContracts() {
      this.isLoading = true;

      const filter = this.makeFilter();

      await ContractApi
        .query(filter)
        .then(({ meta, contracts }) => {
          this.contracts = contracts;
          this.totalItems = meta.total;
          this.meta = meta;
        });

      this.isLoading = false;
    },

    async loadNumContacts() {
      this.isLoading = true;
      await ContactApi.summary()
        .then(({ clients: { total } }) => this.numContacts = total);
      this.isLoading = false;
    },

    makeFilter() {
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }
      return filter;
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="global.services"
      
      :filter="filter"
      :total-items="totalItems"
      :showAddButton="false"
      :page="page"
      :num-pages="numPages"
      :load-page="loadPage"
    >
      <template slot="settings">
        <setting-options>
          <li>
            <a @click="$router.push('/workspace/assets/assetTypes')">
              <i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}
            </a>
          </li>
          <li>
            <a @click="$router.push({ name: 'integrations' })">
              <i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}
            </a>
          </li>
          <hr />
          <li>
            <a :href="$t('contracts_overview.help_services_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_services')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
      <template slot="actions">
        <div class="Contracts-Button-Wrap">
          <button :disabled="!account.readyForContracts" @click="add" class="Button--cta">
            Add {{$t('global.service')}}
          </button>
        </div>
      </template>

      <template slot="warning" v-if="!account.readyForContracts">
        <div class="Message-Title">
          <router-link v-if="!clientsExist" to="/directory/clients">{{$t('contracts_overview.disabled_add_clients')}}</router-link>
          <span v-if="!clientsExist && !offeringsExist">
            {{$t('contracts_overview.disabled_and')}}
          </span>
          <router-link v-if="!offeringsExist" to="/workspace/offerings">{{$t('contracts_overview.disabled_add_offerings')}}</router-link>
          {{$t('contracts_overview.disabled_message')}}
        </div>
      </template>

      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': filter.status == 'active' }"
          @click="filter.resetToDefaults()">
          {{ $t('contracts_overview.filter.active') }}
          <span class="TabCount--ok">{{ meta.active }}</span>
        </div>
        <!-- <div class="PageMeta-tab" :class="{'is-active': filter.status == 'draft'}"
          @click="filter.update('status', 'draft')">
          {{$t('contracts_overview.filter.draft')}}
          <span class="TabCount--warning">0{{meta.draft}}</span>
        </div> -->
        <div
          class="PageMeta-tab"
          :class="{ 'is-active': filter.status == 'scheduled' }"
          @click="filter.update('status', 'scheduled')"
          v-if="meta.scheduled > 0"
        >
          {{ $t('contracts_overview.filter.scheduled') }}
          <span class="TabCount--neutral">{{ meta.scheduled }}</span>
        </div>
        <div
          class="PageMeta-tab"
          :class="{ 'is-active': filter.status == 'finished' }"
          @click="filter.update('status', 'finished')"
          v-if="meta.finished > 0"
        >
          {{ $t('contracts_overview.filter.finished') }}
          <span class="TabCount--danger">{{ meta.finished }}</span>
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': filter.status == 'archived' }"
          @click="filter.update('status', 'archived')">
          {{ $t('contracts_overview.filter.archived') }}
        </div>
      </template>
    </page-meta>

    <div class="PageContents">
      <div class="Container">
        <filter-buttons :filter="filter" />

        <div class="Table">
          <overview-onboarding experience="contracts_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="Spacer2XL"></div>
              <i class="Icon-Base">all_inclusive</i>
            </div>

            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{ $t('onboarding_contracts.contracts_title') }}</h2>
              <div class="OnboardingIntro">{{ $t('onboarding_contracts.contracts_intro') }}</div>

              <p>{{ $t('onboarding_contracts.contracts_steps') }}</p>

              <i18n path="onboarding_contracts.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_contracts.read_more.link')" target="_blank"
                    :title="$t('onboarding_contracts.read_more.title')">
                    {{ $t('onboarding_contracts.read_more.urltext') }}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>

          <div class="Table-header">
            <div class="Table-col--L">
              <sort :sort="filter.sort" field="contact.name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('contracts_overview.columns.title') }}
              </sort>
            </div>
            <div class="Table-col--S Hide-BreakPointSmall">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('global.client') }}
              </sort>
            </div>
            <div class="Table-col--S Hide-BreakPointMedium">
              <span v-if="['finished', 'archived'].includes(filter.status)">
                {{ $t('contracts_overview.columns.ended') }}
              </span>
              <span v-else-if="filter.status == 'scheduled'">
                {{ $t('contracts_overview.columns.starts') }}
              </span>
              <span v-else>
                <sort :sort="filter.sort" field="recurrence.endDate" @toggle="filter.update('sort', $event.sort)">
                  {{ $t('contracts_overview.columns.ends') }}
                </sort>
              </span>
            </div>
            <div class="Table-col--S Table-col--right Hide-BreakPointMedium">
              <sort :sort="filter.sort" field="totalValue" @toggle="filter.update('sort', $event.sort)">
                {{ $t('contracts_overview.columns.amount') }}
              </sort>
            </div>
            <div class="Table-header--spacer" />
          </div>

          <div v-if="isLoading">
            <spinner class="Spinner--general" />
          </div>

          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="!isLoading && hasItems">
            <div v-for="contract in contracts" :key="contract.id" class="Table-row">
              <div class="Table-col--primary Table-col--L" @click="view(contract)">
                <div class="ItemM">
                  <div class="Item-Icon">
                    <icons-services :period="contract.recurrence.interval" />
                  </div>
                  <div class="Item-TextWrap">
                    <div class="Item-Headline">
                      {{ contract.name }}
                    </div>
                    <div class="Description">
                      {{ contract.recurrence.interval | recurrenceInterval }} {{$t('global.services')}}
                      <span v-if="contract.items[0].asset">| {{ contract.items[0]?.asset?.type?.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Table-col--S Hide-BreakPointSmall" >{{ contract.contact?.name }}</div>
              <div class="Table-col--S Hide-BreakPointMedium">
                <span v-if="contract.filter && contract.filter.scheduled" class="Table-Value--exception">
                  <i class="Icon-Base">schedule</i>
                  {{ $t('contract.filter.scheduled') }}
                </span>
                <span v-else-if="['finished'].includes(filter.status)" class="Table-Value--exception Value-Icon">
                      <i class="Icon-Base--danger">error_outline</i>
                      <div>
                        {{ contract.recurrence.endDate | moment('ll') }}
                        <div class="Description">
                          {{$t('contracts_overview.review_finished_service')}}<i class="Icon-Base">archive</i>
                        </div>
                      </div>
                </span>
                <span v-else-if="['archived'].includes(filter.status)" class="Table-Value--exception Value-Icon">
                      <i class="Icon-Base--inline">archive</i>
                      <div>
                        {{ contract.recurrence.endDate | moment('ll') }}
                        <div class="Description">
                          {{$t('contracts_overview.archived_service')}}
                        </div>
                    </div>
                </span>
                <span v-else-if="filter.status == 'scheduled'" class="Table-Value--exception">
                  <i class="Icon-Base">schedule</i>
                  {{ contract.recurrence.startDate | moment('ll') }}
                </span>
                <span v-else-if="contract.recurrence.ends == 'never'" class="Table-Value--notSet Table-Icon" >
                  {{ $t('global.never')}}
                </span>
                <span v-else class="Base-Icon">
                  {{ contract.recurrence | recurrenceEnds }}
                </span>
              </div>
              <div class="Table-col--S Table-col--contractRevenue Hide-BreakPointMedium"
                :class="{'contractRevenue-finished': ['archived','finished'].includes(filter.status)}"
              >
                <div class="Table-Value--amount">
                  {{ contract.totalValue | currency }}
                </div>
                + {{ $t('global.tax') }}
                <div class="Description">
                  {{ contract.recurrence.interval | recurrenceInterval }}

                  <!-- <div v-if="contract.contact.creditCard">
                    <i class="Icon-Base">credit_card</i>
                  </div>
                  <div v-else>
                    <i class="Icon-Base">credit_card_off</i>
                  </div> -->
                  <!-- <i class="Icon-Base">smart_toy</i>
                  <i class="Icon-Base">ads_click</i> -->
                </div>
              </div>
              <div class="Table-col--options">
                <setting-options
                  :options="filter.status === 'archived' ? ['view'] : filter.status === 'active' ? ['view', 'edit', 'stop_archive'] : ['view', 'edit', 'archive']"
                  @view="view(contract)"
                  @edit="edit(contract)"
                  @archive="archive(contract)"
                  @stop_archive="stop_archive(contract)"
                />
              </div>
            </div>
          </div>
        </div>
        <pagination
          :show-all="totalItems < 500"
          :page="page"
          class="Pagination--lower"
          :num-pages="numPages"
          @change="loadPage($event.page)"
        />
      </div>
    </div>
  </div>
</template>
